.small-1 {
    grid-column: span 1;
}

.small-2 {
    grid-column: span 2;
}

.small-3 {
    grid-column: span 3;
}

.small-4 {
    grid-column: span 4;
}

.small-5 {
    grid-column: span 5;
}

.small-6 {
    grid-column: span 6;
}

.small-7 {
    grid-column: span 7;
}

.small-8 {
    grid-column: span 8;
}

.small-9 {
    grid-column: span 9;
}

.small-10 {
    grid-column: span 10;
}

.small-11 {
    grid-column: span 11;
}

.small-12 {
    grid-column: span 12;
}

@media screen and (min-width: 900px) {
    .medium-1 {
        grid-column: span 1;
    }

    .medium-2 {
        grid-column: span 2;
    }

    .medium-3 {
        grid-column: span 3;
    }

    .medium-4 {
        grid-column: span 4;
    }

    .medium-5 {
        grid-column: span 5;
    }

    .medium-6 {
        grid-column: span 6;
    }

    .medium-7 {
        grid-column: span 7;
    }

    .medium-8 {
        grid-column: span 8;
    }

    .medium-9 {
        grid-column: span 9;
    }

    .medium-10 {
        grid-column: span 10;
    }

    .medium-11 {
        grid-column: span 11;
    }

    .medium-12 {
        grid-column: span 12;
    }
}

@media screen and (min-width: 1200px) {
    .large-1 {
        grid-column: span 1;
    }

    .large-2 {
        grid-column: span 2;
    }

    .large-3 {
        grid-column: span 3;
    }

    .large-4 {
        grid-column: span 4;
    }

    .large-5 {
        grid-column: span 5;
    }

    .large-6 {
        grid-column: span 6;
    }

    .large-7 {
        grid-column: span 7;
    }

    .large-8 {
        grid-column: span 8;
    }

    .large-9 {
        grid-column: span 9;
    }

    .large-10 {
        grid-column: span 10;
    }

    .large-11 {
        grid-column: span 11;
    }

    .large-12 {
        grid-column: span 12;
    }
}
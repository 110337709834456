.calendar {
    position: absolute;
    top: 3.5rem;
    left: 0;
    right: 0;
    background-color: var(--color-overlay);
    border-radius: var(--border-radius);
    box-shadow: var(--shadow-raised);
    padding: 1rem;
    z-index: 1001;
}

.calendar .controls {
    display: flex;
    width: 100%;
    margin-bottom: 1rem;
}

.calendar .controls button {
    width: 2rem;
    height: 2rem;
    border-radius: 1rem;
    background-color: transparent;
    border: none;
    padding: 0.25rem;
    margin: 0 1rem;
}

.calendar .controls button:disabled {
    visibility: hidden;
}

.calendar .controls svg {
    fill: var(--color-text);
    color: var(--color-text);
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
}

.calendar .controls button:hover {
    background-color: var(--color-primary);
}

.calendar .controls button:hover svg {
    fill: var(--color-text-inverted);
    color: var(--color-text-inverted);
}

.calendar .controls .month-year {
    flex-grow: 1;
    display: block;
    text-align: center;
    color: var(--color-text);
    height: 2rem;
    line-height: 2rem;
    font-weight: bold;
    font-size: 1rem;
}

.calendar table {
    width: 100%;
}

.calendar td,
.calendar th {
    width: calc(100% / 7);
    height: 2.5rem;
    text-align: center;
}

.calendar th {
    color: var(--color-text);
    font-size: 0.75rem;
    font-weight: bold;
}

.calendar td button {
    width: 2rem;
    height: 2rem;
    border: none;
    border-radius: 1rem;
    line-height: 2rem;
    font-size: 0.875rem;
    background-color: transparent;
    color: var(--color-text);
    transition: all 0.1s ease-in-out;
    cursor: pointer;
}

.calendar td button:disabled {
    color: rgba(255, 255, 255, 0.2);
    cursor: not-allowed;
}

.calendar td button:not(:disabled):hover,
.calendar td button:not(:disabled).active {
    background-color: var(--color-primary);
    color: var(--color-text-inverted);
}

.calendar td button.hidden {
    display: none;
}
.input-dummy {
    position: relative;
    display: block;
}

.input-dummy .label {
    position: absolute;
    color: #202020;
    font-size: 0.75rem;
    top: -1rem;
    padding: 0 6px;
    z-index: 500;
}

.input-dummy .input {
    display: block;
    width: 100%;
    padding: 6px 1rem 0 1rem;
    line-height: 2.5rem;
    border: 1px solid var(--color-border);
    border-radius: var(--border-radius);
    font-size: 0.875rem;
    color: #202020;
    -webkit-appearance: none;
    user-select: none;
    margin-bottom: 0;
}
footer {
    background-color: #202020;
    color: white;
    padding: 2em;
    font-size: 0.75rem;
    font-weight: 400;
}

footer h1,
footer h2,
footer h3,
footer h4 {
    color: white;
    margin: 0;
}

footer .card {
    background-color: #202020;
}

footer .container {
    display: flex;
    justify-content: center;
}

footer h2 {
    color: #f06292;
    font-weight: 400;
}

footer .card {
    display: flex;
    gap: 2em;
    padding: 1em;
    border-right: 1px solid gray;
}

footer .card {
    flex: 1;
}

footer .card:first-child {
    flex: 1.5;
}

footer .card:last-child {
    border: none;
}

footer .card i {
    font-size: 2em;
}

footer .support {
    font-size: 1em;
}

footer .support hr {
    margin: 1em 0;
}

footer h3 {
    max-width: 250px;
    font-weight: 400;
}

footer .contact h2 {
    margin-bottom: 1em;
}

footer .contact h3 {
    margin-bottom: 1em;
}

footer .supports {
    margin: 1em;
    text-align: center;
    font-size: 1.5em;
}

footer .icons {
    margin: 1em auto;
    text-align: center;
    font-size: 1.2em;
    display: flex;
    justify-content: center;
    gap: 2em;
}

footer .icons i {
    font-size: 1.5em;
}

footer .icons a {
    text-decoration: none;
    color: #f06292;
}
.header__language {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    color: var(--color-text);
    font-size: 0.75rem;
}

.header__language__button {
    display: block;
    background: transparent;
    border-radius: 0;
    border: none;
    padding: 0;
    margin: 0;
    font-family: var(--font-family);
    font-weight: 400;
    color: var(--color-text);
    font-size: 0.75rem;
}

.header__language__button:disabled {
    color: var(--color-primary);
    font-weight: 700;
}

.header__language__button:not(:disabled) {
    cursor: pointer;
}

.header__language__button:not(:disabled):hover,
.header__language__button:not(:disabled):focus,
.header__language__button:not(:disabled):active {
    text-decoration: underline;
}

.select {
    position: relative;
}

.select label {
    position: absolute;
    color: #202020;
    font-size: 0.75rem;
    bottom: 4em;
    padding: 0 6px;
}

.select select {
    width: 100%;
    padding: 6px 1rem 0 1rem;
    line-height: 2.5rem;
    border: none;
    border-radius: var(--border-radius);
    font-size: 0.875rem;
    background-color: white;
    color: #202020;
    cursor: pointer;
    -webkit-appearance: none;
}

.select select option {
    color: var(--color-text-inverted);
}
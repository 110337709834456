.hero {
    width: 100%;
    background-image: url('../images/background.jpg');
    background-size: cover;
    background-position: 0 70%;
    position: relative;
    margin-bottom: -13em;
}

form {
    margin-bottom: 6em;
}

.search {
    background-color: rgba(206, 206, 206, 0.8);
    border-radius: 2em;
    padding: 3em 2em;
}

form input,
form select {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 8px 15px rgba(0, 0, 0, 0.1), 0 12px 20px rgba(0, 0, 0, 0.15), 0 18px 30px rgba(0, 0, 0, 0.2), 0 25px 40px rgba(0, 0, 0, 0.3);
    background-color: white;
}

.hero::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.hero h1,
.hero p {
    position: relative;
    z-index: 2;
}

.message {
    visibility: hidden;
}

.hero .grid {
    justify-items: center;
    align-items: center;
    min-height: unset;
    height: 60vh;
}

form {
    position: relative;
    z-index: 4;
}

.hero .headline {
    text-align: center;
    font-size: 3em;
    color: white;
    position: relative;
    bottom: 5em;
}

.hero .subhead {
    text-align: center;
    color: white;
    font-size: 1.5em;
    position: relative;
    bottom: 10em;
}

@media screen and (min-width: 1200px) {
    .hero .grid {
        max-height: calc(100vh - 12rem);
    }
}

.hero .message,
.hero .warning,
.hero .success {
    grid-column: span 12;
    align-self: center;
    padding: 2rem;
    border-radius: var(--border-radius);
    background-color: var(--color-background);
    color: var(--color-text);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

@media screen and (min-width: 900px) {

    .hero .message,
    .hero .warning,
    .hero .success {
        grid-column: 7 / span 6;
    }
}

@media screen and (min-width: 1200px) {

    .hero .message,
    .hero .warning,
    .hero .success {
        grid-column: 8 / span 5;
    }
}

.hero .message h2 {
    margin-bottom: 1rem;
}

.hero .success {
    color: var(--color-text-inverted);
    background-color: var(--color-success);
}

.hero div {
    position: relative;
}

.hero div.success {
    z-index: 12;
}

.hero .success p {
    color: var(--color-text-inverted);
}

.hero .success h2 {
    color: var(--color-text-inverted);
}

.hero .warning {
    color: var(--color-text-inverted);
    background-color: var(--color-warning);
}

.hero .warning p {
    color: var(--color-text-inverted);
}

.hero .warning h2 {
    color: var(--color-text-inverted);
}

@media screen and (max-width: 900px) {
    .hero {
        padding: 5%;
        background-position: 55% top;
        margin-bottom: -2em;
    }

    .hero .grid {
        height: 30vh;
    }

    .hero .headline {
        bottom: 10em;
        font-size: 1.5em;
    }

    .hero .subhead {
        bottom: 15em;
        font-size: 1em;
        width: 65%;
        margin: 0 auto;
    }

    .search {
        background-color: rgba(206, 206, 206, 1);
    }
}
.text {
    position: relative;
    display: block;
    margin-bottom: 2rem;
}

.text label {
    position: absolute;
    color: #202020;
    font-size: 0.75rem;
    /* left: 1rem; */
    top: -1rem;
    /* background-color: var(--color-background); */
    padding: 0 6px;
}

.text textarea {
    display: block;
    width: 100%;
    padding: 6px 1rem 0 1rem;
    font-family: var(--font-family);
    line-height: 2.5rem;
    border: 1px solid var(--color-border);
    border-radius: var(--border-radius);
    background-color: transparent;
    font-size: 0.875rem;
    color: #202020;
    transition: var(--transition);
    min-width: 100%;
    max-width: 100%;
    min-height: 7.5rem;
    max-height: 15rem;
    height: 7.5rem;
}

.text textarea:focus {
    border-color: var(--color-primary);
}

.text textarea:-webkit-autofill,
.text textarea:-webkit-autofill:hover {
    border: 1px solid var(--color-border);
    -webkit-text-fill-color: var(--color-text);
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
    color: var(--color-text)
}

.text textarea:-webkit-autofill:focus {
    border: 1px solid var(--color-primary);
}

.text .error {
    display: block;
    color: var(--color-warning);
    position: absolute;
    font-size: 0.75rem;
    left: 1rem;
    bottom: -0.5rem;
    background-color: var(--color-background);
    padding: 0 6px;
}
.date-time-dummy {
    display: flex;
    position: relative;
}

.date-time-dummy .label {
    position: absolute;
    color: #202020;
    font-size: 0.75rem;
    top: -1rem;
    padding: 0 6px;
}

.date-time-dummy .date {
    width: 70%;
    padding: 6px 1rem 0 1rem;
    line-height: 2.5rem;
    border: 1px solid var(--color-border);
    border-radius: var(--border-radius) 0 0 var(--border-radius);
    font-size: 0.875rem;
    color: #202020;
    user-select: none;
}

.date-time-dummy .time {
    width: 30%;
    border: 1px solid var(--color-border);
    padding: 6px 1rem 0 1rem;
    line-height: 2.5rem;
    border-left: none;
    border-radius: 0 var(--border-radius) var(--border-radius) 0;
    color: #202020;
    font-size: 0.875rem;
    user-select: none;
}
.question {
    box-shadow: var(--shadow-raised);
}

.question button {
    display: flex;
    width: 100%;
    background-color: var(--color-overlay);
    color: var(--color-text);
    border: none;
    padding: 2rem;
    border-top: 1px solid var(--color-border);
    border-radius: 0;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    text-align: left;
}

.question button span {
    display: block;
    flex-grow: 1;
    line-height: 1.5rem;
}

.question svg {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    fill: var(--color-text);
}

.question:first-child button,
.question.open button {
    border-top: none;
    border-radius: var(--border-radius) var(--border-radius) 0 0;
}


.question.open + .question button {
    border-top: none;
}

.question:last-child button {
    border-radius: 0 0 var(--border-radius) var(--border-radius);
}

.question .answer {
    opacity: 0;
    visibility: hidden;
    height: 0;
    transition: opacity 0.3s ease-in-out;
}

.question.open {
    margin: 2rem -2rem;
}

.question.open .answer {
    visibility: visible;
    opacity: 1;
    height: auto;
    margin: 0;
    color: var(--color-text);
    font-size: 0.875rem;
    background-color: var(--color-overlay);
    padding: 0 2rem 2rem 2rem;
    border-radius: 0 0 var(--border-radius) var(--border-radius);
    border-top: 1px solid var(--color-border);
}

.question .answer p,
.question .answer ul,
.question .answer ol {
    margin: 0;
    padding-top: 2rem;
    padding-bottom: 0;
}


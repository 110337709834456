.navigation {
    opacity: 0;
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 0;
    bottom: 0;
    width: 75%;
    left: calc(-100% - 1px);
    min-width: 300px;
    max-width: 320px;
    max-height: 100vh;
    background-color: var(--color-background);
    box-shadow: var(--shadow-raised);
    z-index: 1001;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
}

.navigation > ul {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    list-style: none;
}

.navigation > ul > li {
    display: block;
    margin: 0;
    padding: 0;
    border-bottom: 1px solid var(--color-border);
}

.navigation > ul > li > a {
    display: block;
    line-height: 4.5rem;
    text-align: left;
    margin: 0;
    padding: 0 2rem;
    border: none;
    font-size: 0.875rem;
    font-family: var(--font-family);
    font-weight: bold;
    text-decoration: none;
    color: var(--color-text);
    border-radius: var(--border-radius);
    white-space: nowrap;
}

.navigation > ul > li > a:hover,
.navigation > ul > li > a:focus,
.navigation > ul > li > a:active {
    color: var(--color-text);
    background-color: var(--color-overlay);
}

.navigation > ul > li > button {
    display: block;
    width: 100%;
    line-height: 2.5rem;
    text-align: left;
    padding: 0 1rem;
    border: none;
    font-size: 0.75rem;
    font-family: var(--font-family);
    font-weight: normal;
    text-decoration: none;
    background-color: transparent;
    color: var(--color-text);
    border-radius: var(--border-radius);
    white-space: nowrap;
}

.navigation.open {
    opacity: 1;
    left: 0;
    visibility: visible;
}

@media screen and (min-width: 900px) {
    .navigation {
        opacity: 1;
        position: static;
        flex-direction: row;
        width: auto;
        min-width: auto;
        max-width: none;
        max-height: none;
        box-shadow: none;
        visibility: visible;
        background-color: transparent;
        overflow: visible;
    }

    .navigation > ul {
        flex-direction: row;
        height: auto;
        overflow: visible;
        margin: 0;
        gap: 0.5rem;
    }

    .navigation > ul > li {
        border: none;
        position: relative;
    }

    .navigation > ul > li > a,
    .navigation > ul > li > button {
        line-height: 3.5rem;
        margin: 0;
        padding: 0.5rem 1rem 0.25rem 1rem;
        font-weight: bold;
        border-radius: 0;
        display: block;
        width: 100%;
        font-size: 0.875rem;
        cursor: pointer;
        border-bottom: 0.25rem solid transparent;
        transition: var(--transition);
    }

    .navigation > ul > li > a:hover,
    .navigation > ul > li > a:active,
    .navigation > ul > li > a:focus,
    .navigation > ul > li > a.active,
    .navigation > ul > li > button:hover,
    .navigation > ul > li > button:active,
    .navigation > ul > li > button:focus,
    .navigation > ul > li > button.active {
        color: var(--color-text);
        background-color: transparent;
        border-bottom: 0.25rem solid var(--color-primary);
    }
}

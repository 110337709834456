body {
    background-color: white;
}

.filter-pills .pill,
.transmission-pills .pill {
    background-color: rgb(237, 237, 237);
    color: black;
    padding: 1em;
    margin: 0.5em;
}

.filter-transmission {
    display: flex;
    justify-content: space-between;
}

.vehicle {
    border-radius: 2em;
    background-color: white;
    color: var(--color-text-inverted);
    text-align: center;
    height: 100%;
    padding: 0 2em 1em;
    border: 1px solid #d5d5d5;
}

.vehicle img {
    width: 100%;
    border-radius: var(--border-radius) var(--border-radius) 0 0;
}

.vehicle h3 {
    padding: 2rem 1rem 0 1rem;
    font-size: 1.125rem;
    color: #202020;
    font-weight: 400;
    text-align: left;
}

.vehicle h4 {
    margin: 0;
    padding: 1rem;
    font-size: 1.5rem;
    color: #202020;
}

.vehicle h4 small:first-child {
    padding-right: 0.5rem;
    font-size: 0.875rem;
}

.vehicle h4 small:last-child {
    padding-left: 0.5rem;
    font-size: 0.875rem;
}

.vehicle p {
    margin: 0;
    padding: 1rem;
    font-size: 0.875rem;
}

.vehicle svg path {
    fill: #202020 !important;
}

.vehicle .image {
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: #ffffff;
    border-radius: var(--border-radius) var(--border-radius) 0 0;
    overflow: hidden;
    position: relative;
}

.vehicle-highlight .image {
    border-radius: var(--border-radius);
}

.vehicle .pills .pill,
.pill {
    display: inline-block;
    margin-right: 0.5rem;
    /* margin-bottom: 0.5rem; */
    /* padding: 0.5rem 0.75rem; */
    border: 0;
    border-radius: 1rem;
    font-size: 0.875rem;
    color: var(--color-text);
    background-color: rgba(255, 255, 255, 0.07);
}

.vehicle .pills .pill {
    display: block;
}

.vehicle .pills {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.pill.active {
    background-color: var(--color-primary);
    color: black;
    font-weight: bold;
}

.vehicle .pills .pill span {
    padding-left: 0.5rem;
    color: #202020;
}

.vehicle .action {
    padding: 0.5rem 1rem 2rem 1rem;
}

.vehicle-highlights {
    width: 100%;
    overflow: hidden;
}

.vehicle-highlights .vehicle-highlights {
    width: auto;
    display: inline-flex;
    overflow: visible;
}

.vehicle-highlight {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background-color: #ffffff;
    border-radius: var(--border-radius);
    overflow: hidden;
    margin-right: 2rem;
}

.vehicle-highlight .image {
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: #ffffff;
    position: relative;
    width: 33.5rem;
    max-width: 320px;
    height: calc(33.5rem * 3 / 4);
}

.vehicle-highlight h3 {
    padding: 0.5rem 1rem;
    font-size: 1.5rem;
    color: var(--color-text-inverted);
    text-shadow: 1px 1px #ffffff, 3px 3px var(--color-primary);
}

.vehicle-highlight h4 {
    background-image: linear-gradient(85deg, var(--color-primary), #e02068, #e02068, var(--color-primary));
    color: var(--color-text);
    position: absolute;
    width: 14rem;
    height: 14rem;
    right: -7rem;
    bottom: -9rem;
    transform: rotate(-35deg);
    text-align: center;
    font-size: 0.9375rem;
    line-height: 1;
    padding-top: 0.5rem;
    text-shadow: 1px 1px var(--color-primary), 3px 3px #000000;
    margin: 0;
}

.h4-price-kn {
    margin-bottom: 4px;
    margin-top: 6px;
    font-size: 1.2rem;
}

.h4-price-eur {
    margin-left: 15px;
    font-size: 0.75rem;
}

.h4-price-eur-result-page {
    font-size: 0.875rem;
    margin-left: 25px;
    color: #999;
}

.vehicle-highlight h5 {
    color: var(--color-text-inverted);
    position: absolute;
    bottom: 0.75rem;
    left: 1rem;
    font-size: 1rem;
    letter-spacing: 1px;
    line-height: 1;
    text-shadow: 1px 1px #ffffff, 2px 2px var(--color-primary);
    margin: 0;
}

.vehicle-highlight h4 small:first-child {
    padding-right: 0.5rem;
    font-size: 0.55rem;
    color: var(--color-text);
    font-weight: normal;
    line-height: 1;
    text-shadow: none;
}

.vehicle-highlight h4 small:last-child {
    font-size: 0.55rem;
    color: var(--color-text);
    font-weight: normal;
    line-height: 1;
    position: relative;
    right: -0.45rem;

    text-shadow: none;
}

@media (min-width: 900px) {
    .vehicle-highlight .image {
        width: calc((900px - 6rem) / 2);
        height: calc((900px - 6rem) * 3 / 8);
    }
}

@media (min-width: 1200px) {
    .vehicle-highlight .image {
        width: calc((1200px - 8rem) / 3);
        height: calc((1200px - 8rem) / 4);
    }
}
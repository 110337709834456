.card {
    background-color: var(--color-overlay);
    border-radius: var(--border-radius);
    width: 100%;
    margin: 0 auto;
}

.card img {
    width: 100%;
    border-radius: var(--border-radius) var(--border-radius) 0 0;
}

.card .content {
    padding: 0 2em;
}

.card p {
    font-size: 1rem;
    padding: 0;
    margin: 0;
}

.card .pills {
    display: flex;
    flex-wrap: wrap;
    /* margin-top: 2rem; */
}

.card .pill {
    display: block;
    padding: 1rem;
    margin: 0 1rem 1rem 0;
    border-radius: 2rem;
    font-size: 0.875rem;
    color: var(--color-text);
    background-color: rgba(255, 255, 255, 0.07);
    white-space: nowrap;
}

.card .pill svg {
    margin-right: 0.5rem;
}

.card .pill span {
    margin-left: 0.5rem;
    font-weight: bold;
}

.external-link {
    display: block;
    padding: 1rem;
    margin-bottom: 1rem;
    border-radius: 2rem;
    font-size: 0.875rem;
    color: var(--color-text);
    background-color: rgba(255, 255, 255, 0.07);
    white-space: nowrap;
    text-decoration: none;
}

.card .contact {
    color: var(--color-text);
    display: block;
}

.card .content .phone {
    display: block;
    font-weight: bold;
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
}

.card .content .number {
    display: block;
}
.header-logo {
    display: flex;
    flex-grow: 1;
    padding: 0.25rem 0;
    text-decoration: none;
}

.header-logo > img {
    display: block;
    height: 4rem;
}

@media screen and (min-width: 900px) {
    .header-logo {
        flex-grow: unset;
        margin-right: 2rem;
    }
}

.header {
    width: 100%;
    background-color: var(--color-overlay);
    box-shadow: var(--shadow-raised);
    position: sticky;
    top: 0;
    z-index: 10;
}

.header .ribbon {
    background-color: var(--color-background);
    padding: 0.5rem;
}

.header .ribbon .container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.header .ribbon a {
    display: flex;
    align-items: center;
    height: 1.25rem;
    padding: 0 0.5rem;
    font-size: 0.75rem;
    text-decoration: none;
    color: var(--color-primary);
    opacity: 0.8;
    transition: all 0.2s ease-in-out;
}

.header .ribbon a:hover,
.header .ribbon a:focus {
    background-color: rgba(0, 0, 0, 0.2);
}

.header .ribbon a span {
    margin-left: 0.5rem;
    font-family: var(--font-family);
}

.header .locations {
    display: flex;
    align-items: center;
}

.locations path {
    fill: var(--color-primary) !important;
}

@media (max-width: 480px) {
    .header .ribbon a {
        padding: 0;
    }

    .header .ribbon a svg {
        display: none;
    }
}

@media (max-width: 900px) {
    .header .ribbon .locations {
        display: none;
    }
}
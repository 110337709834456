.input {
    position: relative;
    display: block;
    margin-bottom: 2rem;
}

.input label {
    position: absolute;
    color: #202020;
    font-size: 0.75rem;
    top: -1rem;
    padding: 0 6px;
}

.input input {
    display: block;
    width: 100%;
    padding: 6px 1rem 0 1rem;
    line-height: 2.5rem;
    border: 1px solid var(--color-border);
    border-radius: var(--border-radius);
    background-color: transparent;
    font-size: 0.875rem;
    color: #202020;
    transition: var(--transition);
}

.input input:focus {
    border-color: var(--color-primary);
    color: #202020;
}

.input input:-webkit-autofill,
.input input:-webkit-autofill:hover {
    border: 1px solid var(--color-border);
    -webkit-text-fill-color: #202020;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
    color: #202020;
}

.input input:-webkit-autofill:focus {
    border: 1px solid var(--color-primary);
}

.input .error {
    display: block;
    color: var(--color-warning);
    position: absolute;
    font-size: 0.75rem;
    left: 1rem;
    bottom: -0.5rem;
    background-color: var(--color-background);
    padding: 0 6px;
}

body :not(.contact) div.input {
    padding: 0;
}

.card.contact {
    padding: 2rem;
}

.card.contact h3 span {
    color: white;
}

.contact input {
    padding: 0 1em;
}
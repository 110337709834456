.date-time {
    display: flex;
    position: relative;
}

.date-time label {
    position: absolute;
    color: #202020;
    font-size: 0.75rem;
    bottom: 4em;
    padding: 0 6px;
}

.date-time input {
    width: 70%;
    padding: 6px 1rem 0 1rem;
    line-height: 2.5rem;
    border: none;
    border-radius: var(--border-radius) 0 0 var(--border-radius);
    font-size: 0.875rem;
    background-color: white;
    color: #202020;
    cursor: pointer;
}

.date-time select {
    width: 30%;
    border: none;
    padding: 6px 1rem 0 1rem;
    border-left: none;
    border-radius: 0 var(--border-radius) var(--border-radius) 0;
    appearance: none;
    -webkit-appearance: none;
    background-color: white;
    color: #202020;
    font-size: 0.875rem;
    cursor: pointer;
}
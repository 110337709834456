.container {
    display: flex;
    width: 100%;
    padding: 0 1rem;
    margin: 0 auto;
    max-width: 600px;
}

@media screen and (min-width: 900px) {
    .container {
        max-width: unset;
        width: 900px;
        justify-content: center;
    }
}

@media screen and (min-width: 1200px) {
    .container {
        width: 1200px;
    }
}

.grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 1rem;
    grid-row-gap: 2rem;
    padding: 2rem;
}

@media screen and (min-width: 600px) {
    .grid {
        grid-column-gap: 2rem;
    }
}

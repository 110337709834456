.header-toggle {
    background-color: transparent;
    border: none;
    width: 4.5rem;
    height: 4.5rem;
    line-height: 2.5rem;
    margin: 0 0 0 -1rem;
    padding: 0;
    border-radius: var(--border-radius);
    transition: var(--transition);
    cursor: pointer;
}

.header-toggle > svg {
    margin: 1.5rem 0;
    width: 1.5rem;
    height: 1.5rem;
    fill: var(--color-text);
    color: var(--color-text);
}

.header-toggle:hover,
.header-toggle:focus,
.header-toggle:active {
    background-color: var(--color-overlay);
}

@media screen and (min-width: 900px) {
    .header-toggle {
        display: none;
    }
}
.longtermnew {
  display: flex;
  justify-content: space-between;
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius);
  padding: 1em;
}

.longtermnew p {
  color: var(--color-primary);
  font-size: 1em;
}

@media screen and (max-width: 900px) {
  .longtermnew {
    flex-direction: column;
  }

  .button {
    text-align: center;
  }
}
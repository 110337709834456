.button {
    display: inline-block;
    margin-right: 1rem;
    padding: 0 1.5rem;
    height: 3rem;
    line-height: 3rem;
    border: 0;
    border-radius: var(--border-radius);
    font-size: 0.875rem;
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;
    box-shadow: var(--shadow);
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
}

.button:hover,
.button:focus,
.button:active {
    box-shadow: var(--shadow-raised);
    background-position: 100% 0;
}

.button.block {
    display: block;
    width: 100%;
    padding: 0;
    text-align: center;
}

.button.primary {
    background-color: var(--color-primary);
    color: var(--color-text-inverted);
    background-image: linear-gradient(45deg, var(--color-primary), #e02068, #e02068 , var(--color-primary));
    background-size: 300% 100%;
}

.button.success {
    background-color: var(--color-success);
    color: var(--color-text-inverted);
}

.button.secondary {
    background-color: var(--color-secondary);
    color: var(--color-text-inverted);
}

.button.inverted {
    background-color: var(--color-inverted);
    color: var(--color-text-inverted);
}

.button.inverted:hover,
.button.inverted:focus,
.button.inverted:active {
    box-shadow: var(--shadow-raised);
    opacity: 0.5;
}

.button:last-child {
    margin-right: 0;
}

.button svg {
    vertical-align: middle;
}
.data-row {
    width: 100%;
    position: relative;
    margin-bottom: 2rem;
}

.data-row .label {
    position: absolute;
    color: #202020;
    font-size: 0.75rem;
    top: -1rem;
    -webkit-user-select: none;
    user-select: none;
    padding: 0 6px;
}

.data-row .content {
    display: block;
    width: 100%;
    padding: 6px 1rem 0 1rem;
    line-height: 2.5rem;
    border: 1px solid var(--color-border);
    border-radius: var(--border-radius);
    font-size: 0.875rem;
    font-weight: bold;
    background-color: transparent;
    color: #202020;
    user-select: none;
    -webkit-appearance: none;
}
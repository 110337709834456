.table-container {
    max-width: 100%;
    overflow-x: auto;
}

table.pricing {
    width: 100%;
    max-width: 100%;
    color: #202020;
    border-collapse: collapse;
}

table.pricing td,
table.pricing th {
    border: 1px solid #e2e2e2;
    padding: 1rem;
    font-size: 14px;
}

table.pricing td.left,
table.pricing th.left {
    text-align: left;
}

table.pricing td.right,
table.pricing th.right {
    text-align: right;
}

table.pricing .primary {
    background-color: var(--color-primary);
    color: var(--color-text-inverted);
    font-weight: bold;
}

.eur-hrk-total {
    min-width: 130px;
}
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

*:focus {
    outline: none;
}

html,
body {
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: var(--font-family);
    background-color: white;
    overflow-x: hidden;
    -webkit-text-size-adjust: 100%;
}

.backdrop {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    visibility: hidden;
    z-index: 1000;
    cursor: pointer;
}

.backdrop.open {
    visibility: visible;
}

.backdrop.invisible {
    opacity: 0;
}

iframe {
    min-height: 40vh;
}

@media screen and (min-width: 900px) {
    .backdrop.mobile {
        visibility: hidden;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;
    color: #202020;
}

h1.title {
    margin-bottom: 2rem;
    color: var(--color-primary);
}

h2 {
    margin: 2rem 0;
}

h2:first-child {
    margin-top: 0;
}

h3.day {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
}

h3.day:last-child {
    margin-bottom: 0;
}

h3.day span {
    display: block;
}

p {
    font-size: 0.875rem;
    color: var(--color-text);
    line-height: 1.5;
}

.image-card {
    width: 100%;
    border-radius: 3px;
}

.panel {
    padding: 2rem;
    background-color: var(--color-overlay);
    color: var(--color-text);
    font-size: 0.875rem;
    border-radius: var(--border-radius);
    box-shadow: var(--shadow-raised);
}

.panel li {
    margin: 1rem 0;
}

.contact-details {
    background-color: var(--color-overlay);
    text-align: center;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: var(--shadow-raised);
    flex-wrap: wrap;
    border-radius: var(--border-radius);
}

.contact-details .item {
    height: 3rem;
    line-height: 3rem;
    font-size: 0.875rem;
    color: var(--color-text);
    display: flex;
    align-items: center;
}

.contact-details .item span {
    display: block;
    white-space: nowrap;
}

.contact-details .item svg {
    display: block;
    height: 1.5rem;
    width: 1.5rem;
    margin-right: 1rem;
    fill: var(--color-text);
}

.card.padded p {
    margin-bottom: 2rem;
}

.card.padded p:last-child {
    margin-bottom: 0;
}

a {
    color: var(--color-text);
}

.terms p,
.terms li {
    font-size: 0.875rem;
    color: var(--color-text);
    line-height: 1.5;
    margin-bottom: 1rem !important;
}

.terms h3 {
    font-size: 1.25rem;
    margin-top: 2.5rem;
    margin-bottom: 1.5rem;
}

.terms li {
    margin-bottom: 0.75rem !important;
}

.terms p:last-child {
    margin-bottom: 0 !important;
}

.longterm-banner {
    background-color: var(--color-primary);
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border: 1px solid var(--color-border);
    border-radius: var(--border-radius);
}

.longterm-banner .headings {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.longterm-banner .headings p {
    color: var(--color-text-inverted);
    margin: 0;
    max-width: 800px;
    font-size: 1rem;
}

@media screen and (min-width: 1200px) {
    .longterm-banner {
        flex-direction: row;
    }
}

.new-rules {
    width: 98.7%;
    margin: 0 auto 2rem auto;
    font-size: 0.875rem;
    color: white;
    text-align: left;
    line-height: 1.6;
}

.new-rules table {
    border-collapse: separate;
    border-spacing: 1.5rem;
}

.new-rules li {
    margin: 1rem;
}

.new-rules h3 {
    text-align: center;
    margin: 2rem;
    border-bottom: 1px solid white;
}

.new-rules img {
    width: 150px;
}

.new-rules h4 {
    font-size: 0.875rem;
}

.new-rules h5 {
    font-size: 0.75rem;
}

.new-rules td {
    text-align: center;
}

.new-rules td:not(:first-child) {
    font-size: 1.25rem;
}

.longterm {
    background: rgb(0, 0, 0);
    background: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(204, 204, 204, 1) 50%, rgba(0, 0, 0, 1) 100%);
    background-image: url('../images/kodiaq.png');
    background-position: 0% 60%;
    height: 70vh;
    background-size: cover;
    width: 90vw;
    max-width: 1400px;
    margin: 0 auto;
    border-radius: 16px;
    padding: 2rem;
    color: white;
    font-weight: bold;
    font-size: 1.5em;
    letter-spacing: 3px;
    position: relative;
}

.longterm h2 {
    text-transform: uppercase;
    color: #f06292;
}

.longterm p {
    font-size: 1rem;
    letter-spacing: 0;
    font-weight: 400;
    width: 400px;
    margin-top: 1em;
    line-height: 1.6;
}

.longterm button {
    position: relative;
    top: 3em;
    font-family: Montserrat, sans-serif;
    margin-top: 1em;
    padding: 1em 2em;
    background-color: #f06292;
    border: none;
    color: black;
    font-weight: bold;
    letter-spacing: 1px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: fit-content;
    text-decoration: none;
}

.longterm a {
    text-decoration: none;
    color: black;
}

#vehicles h2 {
    text-align: center;
    padding: 1rem;
    font-weight: bold;
    font-size: 2em;
    letter-spacing: 3px;
    text-transform: uppercase;
    color: #f06292;
}

#vehicles {
    margin: 2em auto 0;

}

#vehicles .cards {
    display: flex;
    justify-content: center;
    gap: 20px;
}

#vehicles .card {
    display: flex;
    flex-direction: column;
    background-color: white;
    color: #000;
    width: 250px;
    border-radius: 8px;
    padding: 8px;
    text-align: center;
}

#vehicles .card h4 {
    font-size: 1em;
    text-align: center;

}

#vehicles .card img {
    width: 100%;
}

#vehicles button {
    display: block;
    width: 20%;
    background-color: #f06292;
    border-radius: 8px;
    border-width: 0;
    color: white;
    cursor: pointer;
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 20px;
    list-style: none;
    margin: 1em auto 2em auto;
    padding: 10px 12px;
    text-align: center;
    transition: all 200ms;
    white-space: nowrap;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}

#vehicles a {
    text-decoration: none;
}

#vehicles .card button:hover {
    background-color: #f06292;
    color: white;
}

.hotel-promo {
    /* background: url('/lounge.png'); */
    background-position: 0% 60%;
    height: 50vh;
    background-size: cover;
    position: relative;
    padding: 2em 20em;
}

.hotel-promo::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.40);
}

.hotel-promo span {
    color: #f06292;
    font-weight: bold;
}

.hotel-promo a {
    display: block;
    margin-top: 1em;
    padding: 1em 2em;
    background-color: #f06292;
    border: none;
    font-size: 0.85em;
    color: black;
    font-weight: bold;
    letter-spacing: 1px;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: fit-content;
    text-decoration: none;
}

.scrolling {
    height: 50vh;
    background-color: black;
    color: white;
}

.hotel-promo h2,
.hotel-promo p,
.hotel-promo a {
    position: relative;
    color: white;
    z-index: 3;
    margin: 0;
}

.hotel-promo a {
    margin-top: 3em;
    text-transform: uppercase;
}

.hotel-promo h2 {
    line-height: 1.6;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.reasons {
    position: relative;
    padding: 50px;
    background-color: white;
    background-size: cover;
    background-position: 0 70%;
    color: white;
}

.section-header {
    text-align: center;
    margin-bottom: 30px;
    font-size: 2em;
}

.section-header h2 {
    position: relative;
    z-index: 2;
    text-align: center;
    padding: 1rem;
    font-weight: bold;
    font-size: 1em;
    letter-spacing: 3px;
    text-transform: uppercase;
    color: #f06292;
}

.card-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.reason-card {
    min-width: 250px;
    max-width: 330px;
    height: 400px;
    padding: 10px;
    border: 1px solid white;
    border-radius: 10px;
    color: white;
    z-index: 2;
    line-height: 1.6;
    position: relative;
    padding: 2em;
}

.reason-card:first-of-type {
    background: url('../images/reasonsugovor.png');
    background-size: cover;
    background-position: 0 40%;
}

.reason-card:nth-of-type(2) {
    background: url('../images/reasonsauto.jpg');
    background-size: cover;
    background-position: 0 40%;
}

.reason-card:nth-of-type(3) {
    background: url('../images/reasonspodrska.png');
    background-size: cover;
    background-position: 0 40%;
}

.reason-card h3 {
    margin-bottom: 10px;
    font-size: 1em;
    color: #f06292;
    font-weight: bold;
    font-size: 1em;
    letter-spacing: 3px;
    text-transform: uppercase;
}

.reason-card p {
    font-size: 1em;
}

.reason-card img {
    width: 200px;
    position: absolute;
    bottom: 0;
    right: 0;
}

#reasons-2 {
    padding: 50px;
    background-color: #f9f9f9;
}

.section-header-2 {
    text-align: center;
    margin-bottom: 30px;
    font-size: 2em;
}

.card-container-2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}

.reason-card-2 {
    background-color: rgb(212, 212, 212);
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.reason-card-2 h3 {
    margin-bottom: 10px;
    font-size: 2em;
}

.reason-card-2 p {
    font-size: 1em;
    color: #555;
}

.news-container {
    width: 100vw;
    margin: 0 auto;
    padding: 3rem;
    display: flex;
    justify-content: center;
    gap: 2em;
    color: white;
}

.news h2 {
    text-align: center;
    font-weight: bold;
    font-size: 2.5em;
    letter-spacing: 7px;
    text-transform: uppercase;
    color: #f06292;
    border-radius: 0.5em;
    display: block;
    width: fit-content;
    margin: 0 auto;
    padding-top: 1em;
}

.news h3 {
    color: white;
    font-weight: 400;
}

.news .headline {
    text-align: center;
}

.news-container .card {
    width: 450px;
    padding-bottom: 2em;
}

.news-container .card img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    display: block;
    margin-bottom: 1em;
    border-radius: 16px;
}

.news-container .content h3 {
    color: #f06292;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 1em;
    margin-top: 2em;
}

.news-container a {
    display: block;
    margin-top: 1em;
    padding: 1em 2em;
    background-color: #f06292;
    border: none;
    font-size: 0.85em;
    color: black;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: fit-content;
    text-decoration: none;
}

.news {
    background-color: #202020;
}

.reservation {
    margin: 0 auto;
    background: unset;
}

.reservation h1 {
    margin-bottom: 2em;
}

.longtermdiv {
    padding: 3em;
    background: url('../images/longtermbg.jpeg');
    background-size: cover;
    background-position-y: bottom;
    background-repeat: no-repeat;

}

.longtermdiv .panel {
    padding: 2em;
    width: 55%;
    margin: 0;
    opacity: 0.95;
}

.longtermdiv ul {
    list-style-type: none;
}

.longtermdiv li {
    padding: 0;
}

.longtermdiv * {
    color: white;
}

.longtermdiv .checkmark {
    color: #f06292;
}

.longtermdiv li {
    position: relative;
    padding-left: 25px;
    /* Space for the checkmark */
    margin-bottom: 10px;
    font-size: 0.875em;
}

.longtermdiv .checkmark {
    position: absolute;
    left: 0;
    font-weight: bold;
}

.offer {
    width: 90%;
    margin: 1em auto;
}

.offer h2 {
    color: #f06292;
    font-weight: bold;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.offer .cards {
    display: flex;
    justify-content: center;
    gap: 2rem;
}

.offer .card {
    background-color: #202020;
    color: white;
    width: fit-content;
    padding: 2em 3em;
    border-radius: 2em;
    margin: 0;
}

.title,
.type {
    color: white;
}

.type {
    font-size: 0.75em;
}

.offer .image {
    background: url('../images/vehicles/sandero.png');
    height: 150px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.offer .card:nth-child(1) .image {
    transform: scale(1.2);
}

.card:nth-child(2) .image {
    background: url('../images/vehicles/kona.png');
    height: 150px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.card:nth-child(3) .image {
    background: url('../images/vehicles/taigo.png');
    height: 150px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.card:nth-child(4) .image {
    background: url('../images/vehicles/vehiclesoctavia.png');
    height: 150px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.offer .info {
    margin-top: 0.5em;
    text-align: left;
    font-size: 0.75em;
}

.offer .vat,
.offer .price {
    text-align: center;
}

.offer .price {
    font-weight: bold;
}

.offer button {
    background-color: #f06292;
    color: white;
    font-weight: bold;
    border: none;
    padding: 1em 2em;
    border-radius: 1em;
    display: block;
    margin: 2em auto 0;
    text-decoration: none;
    text-transform: uppercase;
}

.offer button a {
    text-decoration: none;
    color: #202020;
}

/* Media Queries */

@media screen and (max-width: 900px) {
    html {
        font-size: 14px;
        /* Adjust font size for better readability */
    }

    header {
        position: relative;
    }

    header .nav ul {
        display: block;
        width: 80%;
        /* Make the nav full-width */
        height: auto;
        /* Adjust height for mobile */
        background-color: #202020;
        position: absolute;
        top: 0;
        left: 0;
        transform: translateX(-100%);
        transition: transform 0.3s ease;
        /* Smooth transition */
    }

    header .nav ul.active {
        transform: translateX(0);
    }

    header .nav ul li {
        padding: 1.5em;
        /* Adjust padding for touch targets */
        font-size: 1.1em;
        /* Slightly increase font size for better readability */
    }

    header .nav .mobile {
        display: block;
        margin-left: 20px;
    }

    .hero-content h1 {
        font-size: 1.5em;
    }

    .hero-content p {
        font-size: 1em;
        width: 80%;
        text-align: center;
        margin: 0 auto;
    }

    .hero .search-form {
        flex-direction: column;
        padding: 1em;
        /* Add padding to form */
        width: 90%;
        margin: 0 auto;
        gap: 2em;
    }

    .search-form input,
    .search-button {
        width: 100%;
        /* Make inputs full-width */
    }



    .longterm {
        width: 100%;
        height: 40vh;
        background-position: 60%;
        position: relative;
    }

    .longterm h2 {
        font-size: 1.2em;
        margin-bottom: 0.5em;
    }

    .longterm p {
        margin: 0 auto;
        width: 100%;
    }

    .longterm button {
        position: absolute;
        bottom: 2em;
        left: 1em;
        top: unset;
    }

    .hotel-promo {
        width: 100%;
        text-align: center;
        height: 25vh;
        position: relative;
    }

    .hotel-promo h2 {
        width: 100%;
        margin: 0 auto;
        font-size: 0.875em;
    }

    .hotel-promo p {
        width: 100%;
        margin: 0 auto;
        text-align: center;
        font-size: 0.865em;
    }

    .hotel-promo a {
        display: block;
        margin: 6em auto 0 auto;
        padding: 1em;
        width: 90%;

    }

    .reasons h2 {
        font-size: 0.9em;
        width: 100%;
        padding: 0;
    }

    .reason-card {
        width: 90%;
        height: 35vh;
        background-position: 50% 0;
    }

    .reasons .card-container {
        gap: 2em;
    }

    #vehicles .cards {
        flex-direction: column;
    }

    #vehicles .card {
        width: 90%;
        /* Make vehicle cards responsive */
    }

    #vehicles button {
        width: 90%;
        font-size: 0.8em;
        font-weight: bold;
    }

    footer {
        flex-direction: column;
        /* Stack footer items */
    }

    footer .card {
        border-right: none;
        /* Remove border for single column */
        margin-bottom: 1em;
        /* Add space between cards */
    }

    .hotel-promo {
        padding: 1em;
        /* Reduce padding for mobile */
        text-align: center;
        /* Center align text */
    }

    .longterm {
        padding: 2em 1em;
        /* left: 1em; */
    }

    .news-container {
        flex-direction: column;
    }

    .news .card {
        width: 100%;
        text-align: center;
    }

    .news img {
        height: auto;
        display: block;
        margin: 0 auto;
    }

    footer .container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    footer .card {
        align-items: center;
    }

    footer .icons {
        flex-direction: column;
    }

    footer .icons i {
        font-size: 4em;
    }

    .longtermdiv {
        background-position: 50%;
    }

    .longtermdiv .panel {
        width: 100%;
    }

    .offer .cards {
        flex-direction: column;
        gap: 2em;
    }

    .offer .cards .card {
        width: 100%;
    }
}
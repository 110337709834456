:root {
    --font-family: Montserrat, Verdana, Arial, sans-serif;
    --border-radius: 5px;
    --transition: all 0.1s ease-in-out;
    --color-text: #f4f4f4;
    --color-text-inverted: #000000;
    --color-primary: #f06292;
    --color-secondary: #1a1a1a;
    --color-inverted: #f4f4f4;
    --color-success: #60ad5e;
    --color-info: #5e92f3;
    --color-warning: #ffbd45;
    --color-danger: #cf6689;
    --color-border: #333;
    --color-background: #131313;
    --color-overlay: #1a1a1a;
    --transparent-background: rgba(0, 0, 0, 0.75);
    --transparent-overlay: rgba(255, 255, 255, 0.1);
    --blur: 0.5rem;
    --shadow:
            0 1px 1px 0 rgba(0, 0, 0, 0.14),
            0 2px 1px -1px rgba(0, 0, 0, 0.12),
            0 1px 3px 0 rgba(0, 0, 0, 0.20);
    --shadow-raised:
            0 2px 2px 0 rgba(0, 0, 0, 0.14),
            0 3px 1px -2px rgba(0, 0, 0, 0.12),
            0 1px 5px 0 rgba(0, 0, 0, 0.20);
}

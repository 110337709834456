.alert {
    border-radius: var(--border-radius);
    padding: 0.75rem 1rem;
    width: 100%;
    font-size: 0.875rem;
    box-shadow: var(--shadow-raised);
}

.alert.success {
    background-color: var(--color-success);
    color: var(--color-text-inverted);
}

.alert.primary {
    background-color: #ffffff;
    color: var(--color-text-inverted);
    position: relative;
    margin-top: 2rem;
    width: calc(100% + 4rem);
    display: flex;
    align-items: center;
}

.alert.primary svg {
    margin-right: 0.5rem;
}

.alert.primary:nth-child(2n - 1) {
    right: 0 !important;
}

.alert.primary:nth-child(2n) {
    left: -4rem !important;
}

.alert.danger {
    background-color: var(--color-warning);
    color: var(--color-text-inverted);
}

.alert+.alert.primary {
    margin-top: 1rem;
}